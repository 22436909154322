<template>
  <div class="scheme-contain">
    <div class="affixion-contain">
      <Form :label-width="115">
        <Row>
          <Col span="8" offset="8">
            <Form-item label="训练日期：">
              <Date-picker type="date" :options="options" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
            </Form-item>
            <Form-item label="总消耗能量：">
              <Row>
                <Col :span="22"><Input v-model="trainData.total" placeholder="请输入总消耗量"></Input></Col>
                <Col :span="2">&nbsp;&nbsp;kcal</Col>
              </Row>
            </Form-item>
            <Form-item label="总训练次数：">
              <Row>
                <Col :span="22"><Input v-model="trainData.count" placeholder="请输入总训练次数"></Input></Col>
                <Col :span="2">&nbsp;&nbsp;次</Col>
              </Row>
            </Form-item>
            <Form-item label="总训练时长：">
              <Row>
                <Col :span="22"><Input v-model="trainData.time" placeholder="请输入总训练时长"></Input></Col>
                <Col :span="2">&nbsp;&nbsp;min</Col>
              </Row>
            </Form-item>
          </Col>
        </Row>
        <div class="symbol-box tac">
          <Button type="primary" size="large" @click="handleBack">返回</Button>
          <Button type="success" size="large" @click="handleSave">保存</Button>
        </div>
      </Form>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			trainData: {
				trans_time: '',
				total: '',
				count: '',
				time: '',
			},
			options: {
				disabledDate(date) {
					return date && date.valueOf() >= Date.now();
				},
			},
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	methods: {
		handleBack() {
			this.$router.go(-1);
		},
		handleSave() {
			if (this.validateFormData()) {
				this.trainData.member_id = this.memberId;
				MemberService.transAdd(this.trainData).then(() => {
					this.$router.go(-1);
				});
			}
		},
		handleChangeDate(value) {
			this.trainData.trans_time = value;
		},
		isNumber(arg) {
			return typeof parseFloat(arg) === 'number';
		},
		isPositiveNumber(value) {
			return this.isNumber(value) && value >= 0;
		},
		validateFormData() {
			if (!this.trainData.trans_time) {
				this.$Message.warning('请输入训练日期');
				return false;
			} else if (this.trainData.total === '') {
				this.$Message.warning('请输入总消耗量');
				return false;
			} else if (!this.isPositiveNumber(this.trainData.total)) {
				this.$Message.warning('请输入有效总消耗量');
				return false;
			} else if (this.trainData.count === '') {
				this.$Message.warning('请输入总训练次数');
				return false;
			} else if (!this.isPositiveNumber(this.trainData.count)) {
				this.$Message.warning('请输入有效总训练次数');
				return false;
			} else if (this.trainData.time === '') {
				this.$Message.warning('请输入总训练时长');
				return false;
			} else if (!this.isPositiveNumber(this.trainData.time)) {
				this.$Message.warning('请输入有效总训练时长');
				return false;
			} else {
				return true;
			}
		},
	},
};
</script>
<style lang="css" scoped>
.affixion-contain {
  margin-top: 40px;
}
</style>
